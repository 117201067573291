const updateProjectTitle = async(newProjectTitle, projectId, projects, setProjects) => {
    const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
        const response = await fetch(`${API_BASE_URL}/updateProjectTitle`, {
            method:'PUT',
            headers:{
                'Content-Type': 'application/json',
                "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
            },
            body:JSON.stringify({newProjectTitle, projectId, token}),
        });
        const result = await response.json();
        const updatedProjects = projects.map(project => {
            if(project._id === projectId){
                return result
            }
            return project
        })
        setProjects(updatedProjects)
    }
    catch(error){
        console.error('Error: ', error)
    }
}
export default updateProjectTitle;