const handleSaveImage = ({setCharacters, characters, characterCreatorState, setCharacterCreatorState}) => {
    
    const saveToDb = async (allCharacters) =>{
        const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/saveCharacters`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                    "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
                },
                body:JSON.stringify({allCharacters, token}),
            });
            const result = await response.json();
            console.log(result)
        } catch(error){
            console.error('Error: ', error)
        }
    }
    
    
    const tempCharacters = [...characters]
    tempCharacters.push({name:characterCreatorState.name, age:characterCreatorState.age, gender:characterCreatorState.gender, ethnicity:characterCreatorState.ethnicity, hair:characterCreatorState.hair, clothing:characterCreatorState.clothing, featuresToAvoid:characterCreatorState.featuresToAvoid, image:characterCreatorState.characterPreview, imageId:characterCreatorState.characterPreviewImageId})
    saveToDb(tempCharacters)
    setCharacters(tempCharacters)
    // reset character creator state
    const placeholderImage = require('../Images/characterPlaceholderImage.png')
    setCharacterCreatorState({name:'', age:'', gender:'', ethnicity:'', hair:'', clothing:'', featuresToAvoid:'', characterPreview:placeholderImage, characterPreviewImageId:''})
     
}

export default handleSaveImage