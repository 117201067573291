import React, { useCallback, useEffect, useRef, useState } from 'react';
import {BrowserRouter as Router, Route,Routes, useLocation} from 'react-router-dom'
import './App.css';
import getImageLinks from './Components/getImageLinks.js';
import changeImages from './Components/changeImages.js';
import intervalManager from './Components/intervalManager.js';
import Home from './Pages/Home.js';
import StoryBoardPage from './Pages/StoryBoardPage.js';
import CharacterCreationPage from './Pages/CharacterCreationPage.js';
import getImageLinksCharacters from './Components/getImageLinksCharacters.js';
import handleUpdateCharacterPreview from './Components/handleUpdateCharacterPreview.js';
import ResetPasswordPage from './Pages/ResetPasswordPage.js';

const LocationListener = ({ setLocation }) =>{
  const currentLocation = useLocation();

  useEffect(()=>{
    console.log(currentLocation.pathname)
    setLocation(currentLocation.pathname);
  }, [currentLocation, setLocation])

  return null;
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};



function App() {
  const socketRef = useRef(null);
  const placeholderImage = require('./Images/image_generation_placeholder.jpg')
  const characterPlaceholderImage = require('./Images/characterPlaceholderImage.png')
  const [imageIndex, setImageIndex] = useState(0)
  const [location, setLocation] = useState(window.location.pathname)
  const [characters, setCharacters] = useState([{name:"None", age:'na', gender:'na', ethnicity:'na', hair:'na', clothing:'na', featuresToAvoid:'na', image:'na', imageId:'na'}])
  const [storyboardState, setStoryboardState] = useState({projectTitle:'Untitled Project', projectId:1,storyboard:[{image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:''}, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None',label:'', notes:''}, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None',label:'', notes:''}]}) // each storyboardState item should be strutured as {image:image, itemPrompt: itemPrompt, selectedCharacter:selectedCharacter,label:'', notes:''}
  const [characterCreatorState, setCharacterCreatorState] = useState({name:'', age:'', gender:'', ethnicity:'', hair:'', clothing:'', featuresToAvoid:'', characterPreview:characterPlaceholderImage, characterPreviewImageId:''})
  const [storyboards, setStoryboards] = useState([]);
  const [signUpInfo, setSignUpInfo] = useState({firstName:'', lastName:'', company:'', email:'', password:''});
  const [userInfo,setUserInfo] = useState({projects:[], characters:[], firstName:'', lastName:'', email:'', tokens:0}) //remove data before launch
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const API_BASE_URL = process.env.REACT_APP_API_URL_FOR_WS || 'localhost:4000'
    const socket = new WebSocket(`wss://${API_BASE_URL}`);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        console.log('WebSocket connection closed.');
      }
    };
  }, []);

  

  useEffect(()=>{
    const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
    if (token) {
      const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
      fetch(`${API_BASE_URL}/api/validate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setIsAuthenticated(data.isValid); // Update state based on response
          console.log(data)
          
          setCharacters(data.characters)
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setIsAuthenticated(false); // Set to false if there's an error
        });
        fetch(`${API_BASE_URL}/api/getUserInfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
          },
        })
          .then(response => response.json())
          .then(data => {
            setUserInfo(data)
          })
          .catch(error => {
            console.error('Error validating token:', error);
            setIsAuthenticated(false); // Set to false if there's an error
          });
    } else {
      setIsAuthenticated(false); // No token, set to false
    }
  }, [])

  const reduceTokens = useCallback((purchase) =>{
    
    let amount = 0;
    if(purchase==='newCharacter'){
      amount = 1; 
    }
    else if(purchase === 'newStoryboardImage'){
      amount = 3
    }
    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
    fetch(`${API_BASE_URL}/reduceTokens`,{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
    },
      body:JSON.stringify({
        amount:amount,
        userInfo:userInfo,
      })
    })
    .then(res=>{
      return res.json();
    })
    .then(data=>{
      const tempUserInfo = {...userInfo}
      tempUserInfo.tokens = data.user.tokens
      setUserInfo(tempUserInfo)
    })

  },[userInfo])

  // This is where I keep watch for a websocket message. When they hit, i identify the current page, and replace the relevant image accordingly
  useEffect(() => {
    if (!socketRef.current) return;
      console.log(location)
      const debouncedOnMessage = debounce((event) => {
      
      if (location === "/Storyboard"){
        console.log('recieved websocket message. Currently on location: ' + location)
        const receivedData = JSON.parse(event.data);
        const id = JSON.stringify(receivedData.data.object.id, null, 2)
        intervalManager.clearAllIntervals();
        getImageLinks(JSON.parse(id)).then(function(result){changeImages({image:result,index:imageIndex, storyboardState:storyboardState, setStoryboardState:setStoryboardState})});
        setIsDisabled(false)
        reduceTokens('newStoryboardImage')
      }
      else if(location === "/CharacterCreation"){
        console.log('recieved websocket message. Currently on location: ' + location)
        const receivedData = JSON.parse(event.data);
        const id = JSON.stringify(receivedData.data.object.id, null, 2)
        intervalManager.clearAllIntervals();
        getImageLinksCharacters(JSON.parse(id)).then(function(result){handleUpdateCharacterPreview(result, characterCreatorState, setCharacterCreatorState)});
        setIsDisabled(false)
        reduceTokens('newCharacter')
      }
      
    }, 300);

    socketRef.current.onmessage = debouncedOnMessage;
    return () => {
      if(socketRef.current){
        socketRef.current.onmessage = null;
      }
    };
  },[location,imageIndex, storyboardState, characterCreatorState, reduceTokens]);

  return (
    <Router>
      <LocationListener setLocation = {setLocation}/>
      <Routes>
        <Route exact path ='/' element = {<Home characters={characters} setCharacters={setCharacters} storyboards={storyboards} setStoryboards={setStoryboards} setStoryboardState={setStoryboardState} signUpInfo={signUpInfo} setSignUpInfo={setSignUpInfo} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo}/>}/>
        <Route path = '/Storyboard' element = {<StoryBoardPage setImageIndex = {setImageIndex} imageIndex={imageIndex} placeholderImage={placeholderImage} storyboardState={storyboardState} setStoryboardState = {setStoryboardState} characters = {characters} setCharacters = {setCharacters} storyboards={storyboards} setStoryboards={setStoryboards} signUpInfo={signUpInfo} setSignUpInfo={setSignUpInfo} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>}/>
        <Route path = '/CharacterCreation' element = {<CharacterCreationPage setCharacters={setCharacters} characters={characters} characterCreatorState={characterCreatorState} setCharacterCreatorState = {setCharacterCreatorState} signUpInfo={signUpInfo} setSignUpInfo={setSignUpInfo} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>}/>
        <Route path = '/reset-password/:key' element={<ResetPasswordPage />}/>
      </Routes>
    </Router>
  );
}

export default App;
