import { useState } from 'react';
import { useParams } from 'react-router-dom';
import './ResetPasswordPage.css'

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const { key } = useParams(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
      const response = await fetch(`${API_BASE_URL}/api/reset-password/${key}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
        },
        body: JSON.stringify({ password }),
      });
  
      const data = await response.json();
      if (response.ok) {
        alert('Password reset successful');
      } else {
        alert(data.message || 'Error resetting password');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong');
    }
  };
  

  return (
    <form className='forgot-password-form' onSubmit={handleSubmit}>
      <input
        className='forgot-password-input'
        type="password"
        placeholder="Enter your new password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className='forgot-password-submit-button' type="submit">Set New Password</button>
    </form>
  );
};

export default ResetPasswordPage;
