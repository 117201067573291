

const ListOfCharacters = ({characters, setCharacters, setModalFunctionParams, setModalMessage, setIsModalOpen, setModalFunction}) =>{
    let characterList = []
    const settings_icon = require('../Images/settings-icon.png')
    const handleDeleteCharacter = (selectedCharacters) =>{
        const saveToDb = async (allCharacters) =>{
            const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
            try{
                const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
                const response = await fetch(`${API_BASE_URL}/saveCharacters`, {
                    method:'POST',
                    headers:{
                        'Content-Type': 'application/json',
                        "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
                    },
                    body:JSON.stringify({allCharacters, token}),
                });
                const result = await response.json();
                console.log(result)
            } catch(error){
                console.error('Error: ', error)
            }
        }
    
        const tempCharacters = [...selectedCharacters]
        saveToDb(tempCharacters)
        setCharacters(tempCharacters)
        
    }

    characters.map(character =>{
        if(character.name!=="None"){
            characterList.push(
            <div className="characterCard" id={"character-" + character.imageId}>
                <div className="characterCard-details">
                    <div className="character-name-container">
                        <p className="characterCard-name">{character.name}</p>
                        <div className="settings-container">
                        <img alt="settings icon" className="settings-icon" src={settings_icon}/>
                        <div className="character-settings">
                            <p className="storyboard-setting" onClick={()=>{setModalFunction(()=>handleDeleteCharacter); setModalFunctionParams(characters.filter(item => item.imageId!==character.imageId)); setModalMessage('Are you sure you want to delete this character? This action is irreversable.'); setIsModalOpen(true)}}>Delete Character</p>
                        </div>
                        </div>
                    </div>
                    <p className="characterCard-detail">Age: {character.age}</p>
                    <p className="characterCard-detail">Gender: {character.gender}</p>
                    <p className="characterCard-detail">Ethnicity: {character.ethnicity}</p>
                </div>
                <div className="characterCard-img-container">
                <img alt="Character" className="characterCard-img" src={character.image}/>
                </div>
                
            </div>
            )
            
        }
        return null
    })
    return (<div className="characterList">{characterList}</div>)
}

export default ListOfCharacters