import './SignInCard.css'

const ForgotPasswordCard = ({signUpInfo, setSignUpInfo})=>{
    const exitButton = require('../Images/exit_button.png')

    const handleUserChanges = (field, value) =>{
        const tempSignUpInfo = {...signUpInfo}
        tempSignUpInfo[field] = value
        setSignUpInfo(tempSignUpInfo)
        console.log(tempSignUpInfo)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/api/forgot-password`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                    "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
                },
                body:JSON.stringify({ email:signUpInfo.email}),
            });
            const result = await response.json();
            const sign_in_notification = document.getElementById('forgot-password-notification-message')
            if(result.message !== 'Password reset email sent'){
                sign_in_notification.style.color = '#DF7C52'
                sign_in_notification.innerHTML='User email not found'
                return
            }
            sign_in_notification.style.color = '#6DBF67'
            sign_in_notification.innerHTML=result.message
        }catch(err){
            const sign_in_notification = document.getElementById('forgot-password-notification-message')
            sign_in_notification.style.color = '#DF7C52'
            sign_in_notification.innerHTML='Error sending email'
        }
    }
    
    return(
        <div className="forgot-password-card">
            <div className="sign-in-card-header">
                <h4 className='sign-in-card-title'>Password Reset</h4>
                <img className='exit-button' alt='Exit Button' src={exitButton} onClick={()=>{document.getElementsByClassName("sign-in-card")[0].style.display ="none";document.getElementsByClassName("background-shades")[0].style.display ="none";document.getElementsByClassName("forgot-password-card")[0].style.display='none';document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''}}/>
                <p className='sign-in-card-details'>Remember your password?</p>
                <p className='sign-in-card-link' onClick={()=>{document.getElementsByClassName("sign-in-card")[0].style.display ="block"; document.getElementsByClassName("forgot-password-card")[0].style.display ="none"}}>Sign in.</p>
                <p className='sign-in-notification-message' id='forgot-password-notification-message'></p>
            </div>
            <div className="sign-up-input-section-email">
                <p className='sign-up-input-label'>Email*</p>
                <input className="sign-up-input" type="text" value={signUpInfo.email} onChange={(e)=>handleUserChanges('email', e.target.value.toLowerCase())}/>
            </div>
            <button className='sign-up-button' onClick={(e)=>handleSubmit(e)}>Send Reset Request</button>
        </div>
    )
}

export default ForgotPasswordCard