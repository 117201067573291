const saveStoryboard = (project, storyboards, setStoryboards) =>{

    const saveToDb = async (updatedStoryboard) =>{
        const projectId = project._id;
        const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/saveProject`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                    "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
                },
                body:JSON.stringify({projectId, updatedStoryboard ,token}),
            });
            const result = await response.json();
            console.log(result)
        } catch(error){
            console.error('Error: ', error)
        }
    }

    const index = storyboards.findIndex(item=>item.projectId === project.projectId)
    const tempStoryboards = [...storyboards]
    if(index!== -1){
        tempStoryboards[index] = {...project}
    }
    else{
        tempStoryboards.push({...project})
    }
    saveToDb(project.storyboard)
}

export default saveStoryboard