import './SignInCard.css'

const SignIn = ({signUpInfo, setSignUpInfo, setIsAuthenticated}) => {
    
    
    const exitButton = require('../Images/exit_button.png')

    const signIn = async () => {
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/signin`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                    "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
                },
                body:JSON.stringify({ email:signUpInfo.email, password:signUpInfo.password }),
            });
            const result = await response.json();
            console.log('response: '+ JSON.stringify(result))
            if(JSON.stringify(result) === `{"message":"Invalid email or password"}`){
                document.getElementsByClassName('sign-in-notification-message')[0].style.color='#DF7C52'
                document.getElementsByClassName('sign-in-notification-message')[0].innerHTML='Incorrect username or password.'
                return
            }
            document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''
            localStorage.setItem('cooppStoryboardGenerator_AuthToken', result.token)
            const tempUser = {...result.user}
            tempUser.firstName = result.user.firstName
            tempUser.lastName = result.user.lastName
            tempUser.company = result.user.company
            tempUser.email = result.user.email
            tempUser.password = ""
            setSignUpInfo(tempUser)
            document.getElementsByClassName("sign-in-card")[0].style.display='none'
            document.getElementsByClassName('background-shades')[0].style.display='none'
            setIsAuthenticated(true)
            window.location.reload();
        }
        catch(error){
            console.error('Error: ', error)
        }
    }

    const handleUserChanges = (field, value) =>{
        const tempSignUpInfo = {...signUpInfo}
        tempSignUpInfo[field] = value
        setSignUpInfo(tempSignUpInfo)
        console.log(tempSignUpInfo)
    }

    return(
        
        <div className="sign-in-card">
            <div className="sign-in-card-header">
                <h4 className='sign-in-card-title'>Sign in</h4>
                <img alt='Exit Button' className='exit-button' src={exitButton} onClick={()=>{document.getElementsByClassName("sign-in-card")[0].style.display ="none";document.getElementsByClassName("background-shades")[0].style.display ="none";document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''}}/>
                <p className='sign-in-card-details'>Don't have an account?</p>
                <p className='sign-in-card-link' onClick={()=>{document.getElementsByClassName("sign-up-card")[0].style.display ="block"; document.getElementsByClassName("sign-in-card")[0].style.display ="none"}}>Sign up.</p>
                <p className='sign-in-notification-message'></p>
            </div>
            <div className="sign-up-input-section-email">
                <p className='sign-up-input-label'>Email*</p>
                <input className="sign-up-input" type="text" value={signUpInfo.email} onChange={(e)=>handleUserChanges('email', e.target.value.toLowerCase())}/>
            </div>
            <div className="sign-up-input-section-password">
                <p className='sign-up-input-label'>Password*</p>
                <input className="sign-up-input" type="password" value={signUpInfo.password} onChange={(e)=>handleUserChanges('password', e.target.value)}/>
                <p className='forgot-password' onClick={()=>{document.getElementsByClassName("forgot-password-card")[0].style.display ="block"; document.getElementsByClassName("sign-in-card")[0].style.display ="none"}}>Forgot password?</p>
            </div>
            <button className='sign-up-button' onClick={()=>signIn()}>Sign In</button>
        </div>
       
    )
}

export default SignIn