const deleteProject = async (projectId, setProjects) => {
  const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken');
  try {
    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${API_BASE_URL}/deleteProject`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
      },
      body: JSON.stringify({ projectId, token }),
    });
    const result = await response.json();
    //force window refresh
    window.location.reload();
  } catch (error) {
    console.error('Error: ', error);
  }
}

export default deleteProject;