const getImageLinksCharacters = async(id) =>{
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'  
        const response = await fetch(`${API_BASE_URL}/get-generated-images`, {
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                "X-API-SECRET": process.env.REACT_APP_NXTVID_API_SECRET,
            },
            body:JSON.stringify({id:id}),
        });
        const result = await response.json();
        return(result)
    } catch(error){
        console.error('Error: ', error)
    }
}

export default getImageLinksCharacters